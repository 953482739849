import express, { Application, IRouterHandler, RequestHandler } from "express";
import type { IRouterMatcher, ParamsDictionary, RequestHandlerParams } from "express-serve-static-core";
import { ParsedQs } from "qs";
import { Object } from "ts-toolbelt";
import { SequentialSchemaType } from "@smartrr/shared/entities/Sequential";
import { ISupportForm } from "@smartrr/shared/entities/SupportForm";
import { LoyaltyApi } from "@smartrr/shared/interfaces/loyalty/api";
import { LoyaltyValidation } from "@smartrr/shared/interfaces/loyalty/validation";
import type { IOrganization } from "../entities/Organization";
import { IAddPasswordReq, ILoginReq } from "./reqInterfaces";
import { paths } from "../../backend/openapi/vendor";
import { apiPrefixedHost, vendorApiRoutePrefix } from "../constants";
import { ICancellationReason } from "../entities/CancellationRelationship";
import { ISODateString } from "../entities/ISODateString";
import { IPurchasable, IPurchasableCreate } from "../entities/Purchasable";
import { IPurchaseState } from "../entities/PurchaseState";
import {
  AddonSellingPlanGroupUpdateParams,
  ISellingPlanGroupCreate,
  ISellingPlanGroupUpdate,
  SellingPlanGroupApiResponse,
} from "../entities/SellingPlanGroup";
import { ICreateSmartrrApiToken, ISmartrrApiToken } from "../entities/SmartrrApiToken";
import { IVendorUser } from "../entities/VendorUser";
import { Req, ReqMiddleware, TypedBodyRequestInit, TypedBodyRequestNoReqBodyInit } from "../req";
import { IProductStreamEmission } from "../shopifyGraphQL/purchasables";
import { ISubscriptionContractStreamEmission } from "../shopifyGraphQL/subscriptionContracts";
import { SequentialProducts } from "../interfaces/sequential/api";
import { CombinedSequential, SequentialTransformationResult } from "../interfaces/sequential/misconfigured";
import { NotificationEmails } from "../entities/CustomerEmailConfig/schemas";

//
// route definitions
//

type ExistsOrObj<T, OrObj> = T extends {} ? T : OrObj;
type NullableExistsOrObj<T, OrObj> = T extends {} ? T : T extends null ? null : OrObj;

type ReqResFromOAPI<Url extends keyof paths, Method extends keyof paths[Url]> = IReqRes<
  NullableExistsOrObj<Object.Path<paths, [Url, Method, "requestBody", "content", "application/json"]>, void>,
  NullableExistsOrObj<Object.Path<paths, [Url, Method, "responses", 200, "content", "application/json"]>, void>,
  ExistsOrObj<Object.Path<paths, [Url, Method, "parameters", "path"]>, {}>,
  ExistsOrObj<Object.Path<paths, [Url, Method, "parameters", "query"]>, {}>
>;
interface GetRoutes extends RoutesInterfaceGuarantee {
  // auth
  "/auth/app-public-key": ReqResFromOAPI<"/auth/app-public-key", "get">;

  "/auth/session": ReqResFromOAPI<"/auth/session", "get">;

  "/auth/logout": ReqResFromOAPI<"/auth/logout", "get">;

  "/auth/portal-session": ReqResFromOAPI<"/auth/portal-session", "get">;

  "/organizations": ReqResFromOAPI<"/organizations", "get">;

  // organization
  "/purchasable": ReqResFromOAPI<"/purchasable", "get">;

  "/purchasable/:id": ReqResFromOAPI<"/purchasable", "get">;

  "/extensions/api-token": ReqResFromOAPI<"/extensions/api-token", "get">;

  "/integrations/stripe": ReqResFromOAPI<"/integrations/stripe", "get">;

  "/integrations/stripe/publishable": ReqResFromOAPI<"/integrations/stripe/publishable", "get">;

  "/integrations/stripe/authorize": ReqResFromOAPI<"/integrations/stripe/authorize", "get">;

  "/purchasable-collection": ReqResFromOAPI<"/purchasable-collection", "get">;

  "/customer-portal-theme": ReqResFromOAPI<"/customer-portal-theme", "get">;

  "/customer-email-config": IReqRes<{}, NotificationEmails.ApiResponseType>;

  "/analytics-simple": ReqResFromOAPI<"/analytics-simple", "get">;

  "/looker-embed": ReqResFromOAPI<"/looker-embed", "get">;

  "/order": ReqResFromOAPI<"/order", "get">;

  "/order/formatted": ReqResFromOAPI<"/order/formatted", "get">;

  "/oldest-product-processed-date": ReqResFromOAPI<"/oldest-product-processed-date", "get">;

  "/oldest-subscription-contract-processed-date": ReqResFromOAPI<
    "/oldest-subscription-contract-processed-date",
    "get"
  >;

  "/bill": ReqResFromOAPI<"/bill", "get">;

  "/bill/:billId/history": ReqResFromOAPI<"/bill/:billId/history", "get">;

  "/smartrr-api-token": ReqResFromOAPI<"/smartrr-api-token", "get">;

  "/purchase-state": ReqResFromOAPI<"/purchase-state", "get">;

  "/selling-plan-configs": ReqResFromOAPI<"/selling-plan-configs", "get">;

  "/purchase-state/:shopifySubscriptionId": ReqResFromOAPI<"/purchase-state/:shopifySubscriptionId", "get">;

  "/purchase-state/:shopifySubscriptionId/orders": ReqResFromOAPI<
    "/purchase-state/:shopifySubscriptionId/orders",
    "get"
  >;

  "/purchase-state/:shopifySubscriptionId/events": ReqResFromOAPI<
    "/purchase-state/:shopifySubscriptionId/events",
    "get"
  >;

  "/purchase-state/:shopifySubscriptionId/bills": ReqResFromOAPI<
    "/purchase-state/:shopifySubscriptionId/bills",
    "get"
  >;

  "/selling-plan-group": ReqResFromOAPI<"/selling-plan-group", "get">;

  "/add-ons-config": ReqResFromOAPI<"/add-ons-config", "get">;

  "/customer-relationship": ReqResFromOAPI<"/customer-relationship", "get">;

  "/webhooks-listening": ReqResFromOAPI<"/webhooks-listening", "get">;

  "/theme/:themeId/asset": ReqResFromOAPI<"/theme/:themeId/asset", "get">;

  "/integrations": ReqResFromOAPI<"/integrations", "get">;

  "/countries/data": ReqResFromOAPI<"/countries/data", "get">;

  "/integrations/recharge/customer-processed-count": ReqResFromOAPI<
    "/integrations/recharge/customer-processed-count",
    "get"
  >;

  "/integrations/recharge/customers": ReqResFromOAPI<"/integrations/recharge/customers", "get">;

  "/integrations/recharge/purchase-states": ReqResFromOAPI<"/integrations/recharge/purchase-states", "get">;

  // webhooks
  "/webhook": ReqResFromOAPI<"/webhook", "get">;

  // TODO: Convert these later
  "/shopify-api": NoReqBodyReqRes<{ [key: string]: string[] }, {}>;

  // admin routes for inspecting users
  "/users/:id": NoReqBodyReqRes<string | null, { id: string }>;
  "/users/all": NoReqBodyReqRes<string>;
  "/organizations/:id": NoReqBodyReqRes<string | null, { id: string }>;
  "/organizations/all": NoReqBodyReqRes<string>;

  "/smartrr-script": ReqResFromOAPI<"/smartrr-script", "get">;

  "/selling-plan-groups": NoReqBodyReqRes<SellingPlanGroupApiResponse[]>;
  "/selling-plan-groups/:id": NoReqBodyReqRes<SellingPlanGroupApiResponse, { id: string }>;
  "/selling-plan-groups/trending-list": NoReqBodyReqRes<SellingPlanGroupApiResponse>;
  "/selling-plan-groups/addon": NoReqBodyReqRes<SellingPlanGroupApiResponse>;

  "/sequentials/products": IReqRes<
    { limit: number; offset: number; groupId: string; purchasableName?: string },
    SequentialProducts
  >;

  "/sequentials": IReqRes<
    {
      pageNumber: string;
      groupId: string;
    },
    SequentialTransformationResult
  >;

  "/sequentials/:id/download/csv": IReqRes<
    {},
    string,
    {
      id: string;
    }
  >;

  "/reward-program": IReqRes<{}, LoyaltyApi.Program.Type>;
}

interface PostRoutes extends RoutesInterfaceGuarantee {
  "/auth-tickets/issue": ReqResFromOAPI<"/auth-tickets/issue", "post">;

  "/bill/:billId/retry": ReqResFromOAPI<"/bill/:billId/retry", "post">;

  "/integrations/stripe/deauthorize": ReqResFromOAPI<"/integrations/stripe/deauthorize", "post">;

  "/customer-portal-theme": ReqResFromOAPI<"/customer-portal-theme", "post">;

  "/extensions/update-shop": ReqResFromOAPI<"/extensions/update-shop", "post">;

  "/customer-email-config": IReqRes<NotificationEmails.Type, NotificationEmails.ApiResponseType>;

  "/smartrr-script": ReqResFromOAPI<"/smartrr-script", "post">;

  "/billing-time": ReqResFromOAPI<"/billing-time", "post">;

  "/billing-retry-config": ReqResFromOAPI<"/billing-retry-config", "post">;

  "/order/:orderId/open": ReqResFromOAPI<"/order/:orderId/open", "post">;

  "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/discount": ReqResFromOAPI<
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/discount",
    "post"
  >;

  "/purchase-state": ReqResFromOAPI<"/purchase-state", "post">;

  "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/line-item": ReqResFromOAPI<
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/line-item",
    "post"
  >;

  "/cancellation-reasons": NoReqBodyReqRes<ICancellationReason[], {}>;

  // webhooks
  "/webhook": ReqResFromOAPI<"/webhook", "post">;

  // TODO: Convert these later
  // password auth (not used right now)
  "/auth/signup": IReqRes<ILoginReq, IVendorUser | null>;
  "/auth/login": IReqRes<ILoginReq, IVendorUser | null>;
  "/auth/addPassword": IReqRes<IAddPasswordReq, IVendorUser | null>;

  "/smartrr-api-token": IReqRes<
    { smartrrApiTokenConfig: ICreateSmartrrApiToken },
    { token: ISmartrrApiToken },
    {}
  >;

  "/shopify-subscription-contract-sync": IReqRes<
    {
      shopifySubscriptionContractEmission: ISubscriptionContractStreamEmission;
      syncDate: ISODateString;
    },
    {}
  >;

  "/shopify-subscription-contract-sync/complete": IReqRes<
    {
      syncDate: ISODateString;
    },
    void,
    {}
  >;

  "/shopify-product-sync": IReqRes<
    {
      shopifyProductEmission: IProductStreamEmission;
      syncDate: ISODateString;
    },
    IPurchasable,
    {}
  >;

  "/shopify-product-sync/:productId": IReqRes<
    {
      purchasable: IPurchasableCreate;
      syncDate: ISODateString;
    },
    IPurchasable,
    { productId: string }
  >;

  "/shopify-product-sync/complete": IReqRes<
    {
      syncDate: ISODateString;
    },
    void,
    {}
  >;

  "/shopify-historical-orders-sync/complete": IReqRes<
    {
      syncDate: ISODateString;
    },
    void,
    {}
  >;

  "/shopify-api": IReqRes<{ api: string; action: string; params?: any[] }, any, {}>;

  "/webhooks-listening": IReqRes<
    void,
    {
      isFullySubscribed: boolean;
      subscribedTopics: string[];
      missingTopics?: string[];
    },
    {}
  >;

  "/selling-plan-groups": IReqRes<ISellingPlanGroupCreate, SellingPlanGroupApiResponse>;

  "/selling-plan-groups/addon": IReqRes<void, SellingPlanGroupApiResponse>;

  "/selling-plan-groups/trending-list": IReqRes<void, SellingPlanGroupApiResponse>;

  "/sequentials": IReqRes<SequentialSchemaType, CombinedSequential>;

  "/reward-program": IReqRes<Partial<LoyaltyValidation.Program.Type>, LoyaltyApi.Program.Type>;

  "/reward-program/create": IReqRes<{}, LoyaltyApi.Program.Type>;
  "/reward-program/enable": IReqRes<{}, LoyaltyApi.Program.Type>;

  "/support/tickets": IReqRes<ISupportForm, {}>;
}

interface PutRoutes extends RoutesInterfaceGuarantee {
  "/purchasable": ReqResFromOAPI<"/purchasable", "put">;

  "/theme/:themeId/asset": ReqResFromOAPI<"/theme/:themeId/asset", "put">;

  "/theme/:themeId/portal-token": ReqResFromOAPI<"/theme/:themeId/portal-token", "put">;

  "/integration": ReqResFromOAPI<"/integration", "put">;

  "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/line-item/:lineItemId": ReqResFromOAPI<
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/line-item/:lineItemId",
    "put"
  >;

  "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/line-item/:lineItemId/remove": ReqResFromOAPI<
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/line-item/:lineItemId/remove",
    "put"
  >;

  "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/shipping-price": ReqResFromOAPI<
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/shipping-price",
    "put"
  >;

  "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/update-external-subscription-dates": ReqResFromOAPI<
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/update-external-subscription-dates",
    "put"
  >;

  "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/address": ReqResFromOAPI<
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/address",
    "put"
  >;

  "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/pause": ReqResFromOAPI<
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/pause",
    "put"
  >;

  "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/activate": ReqResFromOAPI<
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/activate",
    "put"
  >;

  "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/cancel": ReqResFromOAPI<
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/cancel",
    "put"
  >;

  "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/skip": ReqResFromOAPI<
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/skip",
    "put"
  >;

  "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/unskip": ReqResFromOAPI<
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/unskip",
    "put"
  >;

  "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/next-billing-date": ReqResFromOAPI<
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/next-billing-date",
    "put"
  >;

  "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/selling-plan": ReqResFromOAPI<
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/selling-plan",
    "put"
  >;

  "/integrations/recharge/customers/:rechargeCustomerId/retry": ReqResFromOAPI<
    "/integrations/recharge/customers/:rechargeCustomerId/retry",
    "put"
  >;

  // webhooks
  "/webhook/:webhookId": ReqResFromOAPI<"/webhook/:webhookId", "put">;

  // TODO: Figure out later
  "/smartrr-api-token/:tokenId": IReqRes<
    { smartrrApiTokenConfig: ICreateSmartrrApiToken },
    { token: ISmartrrApiToken },
    { tokenId: string }
  >;

  "/cancellation-reason/:cancellationReasonId": ReqResFromOAPI<
    "/cancellation-reason/:cancellationReasonId",
    "put"
  >;

  "/smartrr-script/:scriptId": ReqResFromOAPI<"/smartrr-script/:scriptId", "put">;

  "/selling-plan-groups/:id": IReqRes<ISellingPlanGroupUpdate, SellingPlanGroupApiResponse>;
  "/selling-plan-groups/trending-list": IReqRes<ISellingPlanGroupUpdate, SellingPlanGroupApiResponse>;
  "/selling-plan-groups/addon": IReqRes<AddonSellingPlanGroupUpdateParams, SellingPlanGroupApiResponse>;

  "/sequentials/:id": IReqRes<
    SequentialSchemaType,
    CombinedSequential,
    {
      id: string;
    }
  >;

  "/rewards/enable-otp": IReqRes<{}, LoyaltyApi.Program.Type>;
}

interface DeleteRoutes extends NoReqBodyRoutesInterfaceGuarantee {
  "/order/:orderId": ReqResFromOAPI<"/order/:orderId", "delete">;

  "/integration/:integrationId": ReqResFromOAPI<"/integration/:integrationId", "delete">;

  "/smartrr-api-token/:tokenId": ReqResFromOAPI<"/smartrr-api-token/:tokenId", "delete">;

  "/theme/:themeId/asset/:assetKey": ReqResFromOAPI<"/theme/:themeId/asset/:assetKey", "delete">;

  "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/discount/:discountVendorId": ReqResFromOAPI<
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/discount/:discountVendorId",
    "delete"
  >;

  // webhooks
  "/webhook/:webhookId": ReqResFromOAPI<"/webhook/:webhookId", "delete">;

  // TODO: Later
  // admin route for deleting users
  "/users/delete/:id": NoReqBodyReqRes<null, { id: string }>;

  "/cancellation-reason/:cancellationReasonId": ReqResFromOAPI<
    "/cancellation-reason/:cancellationReasonId",
    "delete"
  >;

  "/smartrr-script/:scriptId": ReqResFromOAPI<"/smartrr-script/:scriptId", "delete">;

  "/selling-plan-groups/:id": NoReqBodyReqRes<void, { id: string }>;

  "/sequentials/:id": NoReqBodyReqRes<void, { id: string }, { cancelSubs?: "true" | "false" }>;
}

export interface WSRoutes {
  "/organization/:orgId/sync/orders": IWSReqRes<
    { orgId: string; force: boolean },
    { count: number; done: boolean }
  >;
  "/organization/:orgId/sync/products": IWSReqRes<
    { orgId: string; force: boolean },
    { count: number; done: boolean }
  >;
  "/organization/:orgId/sync/contracts": IWSReqRes<
    { orgId: string; force: boolean; reverse: boolean },
    { count: number; done: boolean }
  >;
  "/organization/:orgId/sync/recharge": IWSReqRes<
    { orgId: string; force: boolean; active: boolean },
    { count: number; done: boolean }
  >;
  "/organization/:orgId/migrate/recharge": IWSReqRes<
    { orgId: string; force: boolean },
    { customerPurchaseState: IPurchaseState | null; count: number; done: boolean }
  >;
  "/organization/:orgId/rollback/recharge": IWSReqRes<
    { orgId: string },
    { customerPurchaseState: IPurchaseState | null; count: number; done: boolean }
  >;
  "/organization/:orgId/sync/recharge/orders": IWSReqRes<
    { orgId: string },
    { customerPurchaseState: IPurchaseState | null; count: number; done: boolean }
  >;
  "/organization/:orgId/sync/recharge/cancelled": IWSReqRes<
    { orgId: string },
    { customerPurchaseState: IPurchaseState | null; count: number; done: boolean }
  >;
  "/organization/:orgId/sync/recharge/initialSubmissionDate": IWSReqRes<
    { orgId: string },
    { customerPurchaseState: IPurchaseState | null; count: number; done: boolean }
  >;
  "/selling-plan-groups/actions/pull": IWSReqRes<void, { completed: number; total: number; done: boolean }>;
}

//
// Consumption of typed routes for front end begins here
//

export type SessionTokenMiddleware = <T extends TypedBodyRequestNoReqBodyInit>(
  endpoint: string,
  requestConfig: T
) => Promise<T>;

export class TypedFrontendVendorApi extends Req {
  // Base Req class doesn't support async middlewares, so we inject it in custom-typed middleware
  // In the future we would want to support async middlewares
  sessionTokenMiddleware: SessionTokenMiddleware;

  constructor(middlewares: ReqMiddleware[], sessionTokenMiddleware: SessionTokenMiddleware) {
    super(`https://${apiPrefixedHost}${vendorApiRoutePrefix}`, {}, middlewares);
    this.sessionTokenMiddleware = sessionTokenMiddleware;
  }

  async getReq<Path extends keyof ISmartrrRoutes["get"] & string>(
    endpoint: Path,
    requestConfig: TypedBodyRequestNoReqBodyInit<
      ISmartrrRoutes["get"][Path]["Params"],
      ISmartrrRoutes["get"][Path]["Query"]
    > = {}
  ) {
    const request = await this.sessionTokenMiddleware(endpoint, requestConfig);
    return super.getReq<
      ISmartrrRoutes["get"][Path]["ResBody"],
      ISmartrrRoutes["get"][Path]["Params"],
      ISmartrrRoutes["get"][Path]["Query"]
    >(endpoint, request);
  }

  async putReq<Path extends keyof ISmartrrRoutes["put"] & string>(
    endpoint: Path,
    requestConfig: TypedBodyRequestInit<
      ISmartrrRoutes["put"][Path]["ReqBody"],
      ISmartrrRoutes["put"][Path]["Params"],
      ISmartrrRoutes["put"][Path]["Query"]
    > = {}
  ) {
    const request = await this.sessionTokenMiddleware(endpoint, requestConfig);
    return super.putReq<
      ISmartrrRoutes["put"][Path]["ReqBody"],
      ISmartrrRoutes["put"][Path]["ResBody"],
      ISmartrrRoutes["put"][Path]["Params"],
      ISmartrrRoutes["put"][Path]["Query"]
    >(endpoint, request);
  }

  async postReq<Path extends keyof ISmartrrRoutes["post"] & string>(
    endpoint: Path,
    requestConfig: TypedBodyRequestInit<
      ISmartrrRoutes["post"][Path]["ReqBody"],
      ISmartrrRoutes["post"][Path]["Params"],
      ISmartrrRoutes["post"][Path]["Query"]
    > = {}
  ) {
    const request = await this.sessionTokenMiddleware(endpoint, requestConfig);
    return super.postReq<
      ISmartrrRoutes["post"][Path]["ReqBody"],
      ISmartrrRoutes["post"][Path]["ResBody"],
      ISmartrrRoutes["post"][Path]["Params"],
      ISmartrrRoutes["post"][Path]["Query"]
    >(endpoint, request);
  }

  async deleteReq<Path extends keyof ISmartrrRoutes["delete"] & string>(
    endpoint: Path,
    requestConfig: TypedBodyRequestNoReqBodyInit<
      ISmartrrRoutes["delete"][Path]["Params"],
      ISmartrrRoutes["delete"][Path]["Query"]
    > = {}
  ) {
    const request = await this.sessionTokenMiddleware(endpoint, requestConfig);
    return super.deleteReq<
      ISmartrrRoutes["delete"][Path]["ResBody"],
      ISmartrrRoutes["delete"][Path]["Params"],
      ISmartrrRoutes["delete"][Path]["Query"]
    >(endpoint, request);
  }
}

//
// Consumption of typed routes for back end (Express) begins here
//

interface ISmartrrRoutes {
  get: { [K in keyof GetRoutes]: GetRoutes[K] };
  post: { [K in keyof PostRoutes]: PostRoutes[K] };
  put: { [K in keyof PutRoutes]: PutRoutes[K] };
  delete: { [K in keyof DeleteRoutes]: DeleteRoutes[K] };
}

export type HTMLRes = string;

interface IReqRes<
  ReqBody extends {} | void | null,
  ResBody extends {} | HTMLRes | null | void = void,
  ReqParams extends ParamsDictionary = {},
  ReqQuery extends ParsedQs = {},
> extends BaseReqRes {
  Params: ReqParams;
  ReqBody: ReqBody;
  ResBody: ResBody;
  Query: ReqQuery;
}

type NoReqBodyReqRes<
  ResBody extends {} | null | void = void,
  ReqParams extends ParamsDictionary = {},
  ReqQuery extends ParsedQs = {},
> = IReqRes<void, ResBody, ReqParams, ReqQuery>;

// TODO: figure out how to add type arguments here so that the list of routes can be guaranteed
// right now a route not in this list has type "any" for request and response
interface RoutesInterfaceGuarantee {
  // to have full guarantee, get rid of this
  [key: string]: BaseReqRes;
}
interface NoReqBodyRoutesInterfaceGuarantee {
  // to have full guarantee, get rid of this
  [key: string]: BaseReqResNoBody;
}

interface BaseReqRes {
  Params: ParamsDictionary;
  ReqBody: any;
  ResBody: any;
  Query: any;
}

interface BaseReqResNoBody extends BaseReqRes {
  Params: ParamsDictionary;
  ReqBody: void;
  ResBody: any;
  Query: any;
}

interface ISmartrrRouterMatcher<T, Method extends keyof ISmartrrRoutes> {
  <Path extends keyof ISmartrrRoutes[Method]>(
    path: Path,
    ...handlers: RequestHandler<
      Object.Path<ISmartrrRoutes, [Method, Path, "Params"]>,
      Object.Path<ISmartrrRoutes, [Method, Path, "ResBody"]>,
      Object.Path<ISmartrrRoutes, [Method, Path, "ReqBody"]>,
      Object.Path<ISmartrrRoutes, [Method, Path, "Query"]>
    >[]
  ): T;

  <Path extends keyof ISmartrrRoutes[Method]>(
    path: Path,
    ...handlers: RequestHandlerParams<
      Object.Path<ISmartrrRoutes, [Method, Path, "Params"]>,
      Object.Path<ISmartrrRoutes, [Method, Path, "ResBody"]>,
      Object.Path<ISmartrrRoutes, [Method, Path, "ReqBody"]>,
      Object.Path<ISmartrrRoutes, [Method, Path, "Query"]>
    >[]
  ): T;
  <Path extends keyof ISmartrrRoutes[Method]>(path: Path, subApplication: Application): T;
}

// typed express router
export interface ISmartrrVendorApiExpressRouter extends express.Express {
  get: ISmartrrRouterMatcher<this, "get">;
  post: ISmartrrRouterMatcher<this, "post">;
  put: ISmartrrRouterMatcher<this, "put">;
  delete: ISmartrrRouterMatcher<this, "delete">;
  use: IRouterHandler<this> & IRouterMatcher<this>;
}

interface IWSReqRes<Req, ResMessage> {
  req: {
    route: string;
    params: Req;
  };
  res: ResMessage;
}

export interface ISmartrrVendorApiWsRouter {
  ws: <Path extends keyof WSRoutes>(
    path: Path,
    handler: (
      context: WSRoutes[Path]["req"] & { user: IVendorUser; organization: IOrganization },
      send: (res: WSRoutes[Path]["res"]) => void
    ) => Promise<void>
  ) => void;
}
